<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <div class="columns">
      <div class="column">
        <a-card :editable="false">
          <a-input
            field="name"
            type="text"
          />

          <a-select
            field="operator"
            options="operators"
            null-option
          />
        </a-card>
      </div>

      <div class="column">
        <a-input
          field="officialName"
        />

        <a-input
          field="address"
        />

        <a-input
          field="city"
        />

        <a-input
          field="zip"
        />

        <a-autocomplete
          field="country"
          :options="countries"
          option-label="country"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from './formMixin'

export default {
  mixins: [formMixin],
  computed: {
    countries () {
      return _.sortBy(_.uniqBy(this.$store.getters['depots/items'], 'country'), 'country')
    }
  }
}
</script>
